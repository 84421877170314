var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.srcList, function (v, k) {
      return _c(
        "div",
        { key: k },
        [
          _c(
            "el-button",
            {
              staticClass: "box",
              attrs: { type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.goToPage(v.url)
                },
              },
            },
            [_vm._v(_vm._s(v.desc))]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }