<template>
  <div>
    <div v-for="(v,k) in srcList" :key="k">
        <el-button class="box" type="danger" @click="goToPage(v.url)">{{v.desc}}</el-button>
    </div>
    <!-- <el-button class="box" type="danger" @click="goToPage('xsfx')"
      >销售分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('smfx')"
      >扫码分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('scclfx')"
      >市场存量分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('smhdkpfx')"
      >扫码活动开瓶开箱分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('qdjsfx')"
      >渠道建设分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('klfx')"
      >库龄分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('jxcfx')"
      >经销商进销存分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('hwffx')"
      >会务费分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('tghdfx')"
      >推广活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('blhdfx')"
      >包量活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('clhdfx')"
      >陈列活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('yxhdfx')"
      >宴席活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('fxfx')"
      >费销分析</el-button
    > -->
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'report',
  data() {
    return {
      srcList: [],
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      request
        .get('/upload/fine/report/getReportButtons')
        .then((res) => {
          if (res.success) {
            this.srcList = res.result;
          }
        });
    },
    goToPage(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: block;
  margin-left: 0 !important;
  margin-bottom: 10px;
}
</style>
